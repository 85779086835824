
export const htmlString = ` <div>
        <p style="font-size:26pt; line-height:120%; margin:24pt 0pt; orphans:0; text-align:justify; widows:0"><span
                style="font-family:Arial; font-size:26pt; font-weight:bold">Dobit.link Terms of </span><span
                style="font-family:Arial; font-size:26pt; font-weight:bold">Service</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Last updated </span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">August 22</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">, 2023</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> (“</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">our</span><span
                style="font-family:Arial; font-size:11pt">”, “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">we</span><span
                style="font-family:Arial; font-size:11pt">” or “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">us</span><span
                style="font-family:Arial; font-size:11pt">”) provides you with an AI-driven character community (the
                “</span><span style="font-family:Arial; font-size:11pt; font-weight:bold">Platform</span><span
                style="font-family:Arial; font-size:11pt">” or “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold"> Community</span><span
                style="font-family:Arial; font-size:11pt">”) that allows you to create your own AI character, interact
                with and cultivate the character in the Platform, socialize with other users in the </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Community and use contents created by you and other users.
                Except to the extent you and </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> have executed a separate agreement, these terms and
                conditions, including all Additional Terms referenced in Section 1.c below, exclusively govern your
                participation in activities, access to and use of the Platform, applications, communities, contents,
                products and websites (collectively, the “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Service</span><span
                style="font-family:Arial; font-size:11pt">”), and constitute a binding legal agreement between you and
            </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> (the “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Terms</span><span
                style="font-family:Arial; font-size:11pt">”). Unless otherwise indicated, “</span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">” as used throughout the Terms, means</span><span
                style="font-family:Arial; font-size:11pt"> Kotoko Inc. and its designated affiliates</span><span
                style="font-family:Arial; font-size:11pt">.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">1. Acceptance of Terms</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) By using any of our Services, you are agreeing to stick to
                the provisions of these terms, including the policies, rules, guidelines, the Terms, the Additional
                Terms, and any update and modification thereto. If you do not agree to these Terms, you may not use the
                Service. In order to agree to these Terms, you need to (1) be 13 or older or have your parent or
                guardian’s consent (depending on the age restrictions for using similar services in your country or
                residence) to agree to the Terms, and (2) have the power to enter a binding contract with us and not be
                barred from doing so under any applicable laws.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) We reserve the right, at our sole discretion, to change,
                update or modify the Terms at any time with or without notice. These changes will become effective
                immediately upon being posted on this page or through the Service. Your continued use of any of the
                Services following the posting of revised Terms means that you accept and agree to the changes. We
                suggest that you review these Terms frequently to stay informed of the latest modifications.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(c) Your use of the Services is subject to all additional
                terms, policies, rules, or guidelines applicable to the Service that we may post on or link to the
                Service from time to time (the “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Additional Terms</span><span
                style="font-family:Arial; font-size:11pt">”), including our privacy policy. All such Additional Terms
                are incorporated by this reference into, and made a part of, these Terms. If there is any conflict
                between these Terms and the Additional Terms, the Additional Terms govern the relevant part of the
                Service.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">2. The Service</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) You are allowed to do following things through the Service
                on the Platform:</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i.  </span><span
                style="font-family:Arial; font-size:11pt">Create</span><span style="font-family:Arial; font-size:11pt">,
                modify, contribute and upload content, which may be available to all of the users of the Service;</span>
        </p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii.  Interact, play, and use the games and user-generated
                contents (“</span><span style="font-family:Arial; font-size:11pt; font-weight:bold">UGC</span><span
                style="font-family:Arial; font-size:11pt">”) on the Platform;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. Connect, chat, relate and socialize with other users;
                and</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iv. Participate in activities or competitions organized on the
                Platform.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) We reserve the right, at our sole discretion, to modify,
                discontinue or terminate any and all of the Service(s) (or any part thereof) at any time with or without
                notice. You agree that we will not be liable to you or any third party for any modification, suspension,
                discontinuance or termination the Services.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(c)  We may provide tools through the Services that enable you
                to share content(s) on third-party services, including through features that allow you to link your
                Account with an account on a third-party service, such as Facebook or Twitter, or through our
                implementation of third-party buttons (such as “like” or “share” buttons). Third-party services are not
                under our control, and we are not responsible for any third-party service’s use of your content. By
                using the Service, you expressly relieve us from any and all liability arising from your use of any
                third-party services.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">3. </span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold"> Accounts</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a)  In order to use the Service on </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">, you must register for an account (“</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Account</span><span
                style="font-family:Arial; font-size:11pt">”). When you register for an Account or update the
                information, you agree to provide true, accurate, current and complete information about yourself during
                the registration process and to update such information to keep it true, accurate, current and complete.
                We may require you to provide information such as your name, age, email address or phone number, for the
                purpose of proving your identity and ability to use our Services.</span><ins
                style="-aw-revision-author:'GLO'; -aw-revision-datetime:'2023-08-17T17:30:00'"><span
                    style="font-family:Arial; font-size:11pt"> </span></ins></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) You may never allow anyone else to use your Account, or
                disclose your password to any third party. If you have reason to believe that your Account is no longer
                secure, then you must immediately notify us. You should change your password if you think someone whom
                you did not authorize knows what it is. You agree that we may take steps to verify the accuracy of
                information you provide, including contact information for a parent or guardian. We will not be liable
                for any loss or damage arising from your failure to comply with this paragraph.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(c)  </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> reserves the right to suspend or terminate your Account if
                any information provided during the registration process or thereafter proves to be inaccurate, not
                current or incomplete. In addition, we may, at our sole discretion, (a) suspend or terminate your
                Account and/or your access to the Service, or (b) remove any of your content, at any time, with or
                without notice for any reason. We will have no liability whatsoever as a result of any suspension or
                termination of your access to or use of the Service.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(d) </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> is the owner of all right, title, and interest in and to all
                Accounts. All use of an Account shall inure to </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">’s benefit. You may not purchase, sell, gift, rent, trade or
                otherwise dispose of any Account, or offer to purchase, sell, gift, rent, trade or otherwise dispose of
                any Account, and any such attempt shall be null and void and may result in the forfeiture of the
                Account.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(e) You acknowledge that </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> may establish general practices and limits concerning use of
                the Services, including without limitation the maximum period of time that data or other content will be
                retained by the Services and the maximum storage space that will be allotted on </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">’s servers on your behalf. You agree that </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> has no responsibility or liability for the deletion or
                failure to store any data or other content maintained or uploaded to the Services. You acknowledge that
            </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> reserves the right to terminate accounts that are inactive
                for an extended period of time. You further acknowledge that </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> reserves the right to change these general practices and
                limits at any time, in its sole discretion, with or without notice.</span></p>
        <p style="font-size:10.5pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">4. </span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold"> Virtual Currency and Payments</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i.  We, in our sole discretion, may offer eligible users the
                Service to acquire a limited license to use our virtual currency (“</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold"> Virtual Currency</span><span
                style="font-family:Arial; font-size:11pt">”). If we offer you the ability to acquire a limited license
                to use </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency, we hereby grant you a non-exclusive,
                revocable, personal, limited, non-transferable, non-sublicensable right and license to use </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency only for your personal, entertainment use
                exclusively in connection with the Service as permitted by us, subject to these Terms.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii. You can acquire </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency from </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">: (a) by purchasing </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency from us using actual monetary instruments;
                (b) by acquiring certain memberships from us that provide a specified amount of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency; (c) by creating certain content for the
                Service and receiving </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency as reward; or (d) through such other methods
                as we may offer from time to time. We will credit your Account for any </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency acquired by you. Your license to use
            </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency will terminate upon termination of these
                Terms or your Account and as otherwise provided herein, except as otherwise required by applicable law.
            </span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. You are allowed to transfer, assign, sell, gift,
                exchange, trade </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency with other users only through the Service
                and only on the Platform. We will implement tools to track the source of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency in your Account. The only scenario you can
                redeem your </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency for cash is when you are paid by other users
                using </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency as the reward for your creation to the
                Service. Neither we nor any third party has any obligation to exchange </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency for anything of value, including, but not
                limited to, real currency, except as expressly provided in these Terms or otherwise required by
                applicable law. We, in our sole discretion, may impose limits on </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency, including, but not limited to, the amount
                that may be acquired, earned, or redeemed.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iv. </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency do not: (a) have an equivalent value in real
                currency; (b) act as a substitute for real currency; (c) act as consideration for any legally
                enforceable contract (except where we grant a license to use </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency); or (d) earn interest. </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency are not redeemable or exchangeable for real
                currency, monetary value, or convertible virtual currency from us or any other third party, except as
                expressly provided in these Terms or otherwise required by applicable law. Transactions involving the
                exchange of </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency for virtual items or virtual services on the
                Platform are not legally enforceable, may not form the basis of any private right of action against us
                or any third party, and are governed solely by us in our sole discretion and application of these
                Terms.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">v. All payments for </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency are final and not refundable or
                exchangeable, except as required by applicable law. You may not transfer, assign, sell, gift, exchange,
                trade, convert, lease, sublicense, rent, or distribute </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency except through the Service and as expressly
                permitted by us. Any disposition or attempted disposition of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency in violation of these Terms will be void and
                will result in immediate termination of your Account and your license to use </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency. We do not recognize any third-party
                services that may be used to sell, trade, transfer, or otherwise dispose of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency. We do not assume any responsibility for,
                and will not support, such transactions.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">vi. We may at any time regulate, control, modify and eliminate
            </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency as we see fit in our sole discretion. You
                acknowledge and agree that we may engage in actions that may impact the perceived value or acquired
                price of </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency at any time, except as prohibited by
                applicable law.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">vii. Except as otherwise prohibited by applicable law and
                except for the limited licenses granted under these Terms, we reserve and retain all rights, title, and
                interest in and to </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">viii. We, in our sole discretion, have the absolute right to
                manage, modify, suspend, revoke, and terminate your license to use </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency without notice, refund, compensation, or
                liability to you, except as otherwise prohibited by applicable law. We make no guarantee as to the
                nature, quality, or value of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency or the availability or supply
                thereof.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) Making Payments</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i. You are allowed to purchase content, assets, membership,
            </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency or any other content made available to you
                on the Platform. </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> may make available to your various payment processing methods
                to facilitate the purchase of content through the Service. You agree to abide by any relevant terms of
                service or other legal agreement, whether with us or with a third party, that governs your use of a
                given payment processing method. You agree that </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> reserves the right to add or remove payment processing
                methods at its sole discretion and without notice to you. If you are directed to </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">’s third party payment processor, you may be subject to terms
                and conditions governing use of that third party’s service and that third party’s privacy policy. Please
                review such third party’s terms and conditions and privacy policy before using such services.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii. Unless otherwise expressly stated in these Terms or unless
                otherwise agreed to, all payments are final and non-refundable, except as required by applicable law.
                You represent and warrant that you have the authority and right to use the payment method selected by
                you and that such payment method has sufficient credit or funds available to complete your payment. If
                you believe someone has made unauthorized purchases without your permission, you should notify us of the
                charge immediately. We reserve the right to close any Account with unauthorized charges.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. All sales are final and there shall be no refunds except
                as required by applicable law. We will not allow changes to your purchase after you complete it. We will
                have no liability for any losses or damages you may incur as a result, and will not be liable to refund
                any </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> Virtual Currency or other funds you spent in the
                Platform.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">5. Intellectual Property</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) Service</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i.  The visual interfaces, graphics, design, assets,
                compilation, information, data, computer code (including source code or object code), products,
                software, applications, services, and all other elements of the Service (“</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Intellectual Property</span><span
                style="font-family:Arial; font-size:11pt">” or “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">IP</span><span
                style="font-family:Arial; font-size:11pt">”) are protected by applicable laws and regulations. All IP
                contained in the Service is our property or third-party licensors’ property. Except as expressly
                authorized by us in these Terms, you may not make use of any IP contained in the Service unless you get
                separate permission from the owner. We reserve all rights to the IP not granted expressly in these
                Terms.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii.  You are permitted to use the Service for your personal,
                non-commercial use only or legitimate business purposes related to your role as a user of </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">. Subject to your ongoing compliance with these Terms, we
                grant you a non-exclusive, personal, limited, revocable, non-transferable and non-sublicensable license
                to use the Service on compatible devices that you own or control for your personal, entertainment use.
                For clarity, the foregoing permissions are limited to the Service, and no rights are granted with
                respect to any servers, computers, or databases associated with the Service.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. You are not allowed to export any raw data or files of
                any content present on the Platform to your personal devices. You are not allowed to hack, reverse
                engineer, decompile or change our Service, which includes the code or any content or data. You also
                aren’t allowed to create any software or content that mimics our Service or branding. You may not rent,
                lease, lend, sell, redistribute, and sublicense any portion of the Service for any commercial purposes.
                You may not copy, distribute, publicly perform, publicly display, decompile, reverse engineer,
                disassemble, attempt to derive the source code and assets, nor attempt to disable or circumvent any
                security or other technological measure designed to protect the Service, including any protections for
                the Platform.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iv. If you breach these restrictions, or otherwise exceed the
                scope of the licenses granted herein, you may be subject to prosecution and damages, as well as
                liability for infringement of Intellectual Property Rights. We have the right to ask you to provide
                necessary information or documents for the purpose of investigation if such breach is detected.</span>
        </p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b)  Notice and Takedown Procedure</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i.  We respect the Intellectual Property Rights of others and
                we ask you to do the same. If you are an Intellectual Property Rights holder or an agent of an
                Intellectual Property Rights holder and believe that any content on the Service infringes upon your
                Intellectual Property Rights, you may submit a notification by contacting our agent at </span><span
                style="font-family:Arial; font-size:11pt">moc@</span><span
                style="font-family:Arial; font-size:11pt">d</span><span
                style="font-family:Arial; font-size:11pt">obit.</span><span
                style="font-family:Arial; font-size:11pt">link</span><span style="font-family:Arial; font-size:11pt"> by
                providing the following information in writing:</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">1.    An electronic or physical signature of the person
                authorized to act on behalf of the owner of the Intellectual Property Rights being infringed;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">2.    A description of the protected work that you claim has
                been infringed;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">3.    A description of the material that you claim is
                infringing and where it is located;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">4.    Your address, telephone number, and email
                address;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">5.    A statement by you that you have a good faith belief
                that the use of those materials is not authorized by the Intellectual Property Rights holder, its agent,
                or the law; and</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">6.    A statement by you that the above information in your
                notice is accurate and that, under penalty of perjury, you are the Intellectual Property Rights holder
                or authorized to act on the Intellectual Property Rights holder’s behalf.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii. Regarding any content that was removed or disabled, if you
                believe that your content is not infringing or that you have the authorization from the Intellectual
                Property Rights holder, the Intellectual Property Rights holder’s agent, or pursuant to the law, to post
                and use the material in your content, you may send a counter-notice to our agent, providing the proof of
                the authorization or statement of the legitimacy of the use.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. When our agent receives a counter-notice, we may send a
                copy of the counter-notice to the original complaining party informing that party that we may restore
                the removed content or stop disabling it. Unless the Intellectual Property Rights holder files an action
                seeking a court order against the provider of the content, the removed content may be restored after
                receipt of the counter-notice, in our sole discretion.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iv. You acknowledge that if you fail to comply with all of the
                requirements of this Section 5(b), your notice or counter-notice may not be valid. We reserve the right
                to request for more materials from you as we see fit for the above procedures.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">v. </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt">’s intellectual property policy is to: (i) remove or disable
                access to material that </span><span style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> believes in good faith, upon notice from an Intellectual
                Property Rights holder or his or her agent, is infringing the Intellectual Property Rights of a third
                party by being made available through the Service; and (ii) in appropriate circumstances, to terminate
                the Accounts of and block access to the Service by any user who repeatedly or egregiously infringes
                other people’s Intellectual Property Rights.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">6. Third-Party Materials.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) Certain portions of the Service may include, display, or
                make available content, data, information, applications or materials from third parties (“</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Third-Party Materials</span><span
                style="font-family:Arial; font-size:11pt">”). You understand that by using the Service, you may
                encounter Third-Party Materials, such as third-party advertisements and promotional content that may be
                deemed offensive, indecent, or objectionable, which content may or may not be identified as having
                explicit language, and that may contain links or references to objectionable material. Nevertheless, you
                agree to use the Service at your sole risk and that we shall not have any liability to you for content
                that may be found to be offensive, indecent, objectionable, inaccurate, incomplete, untimely, invalid,
                illegal, of poor quality or otherwise.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) In addition, Third-Party Materials that may be accessed
                from, displayed on or linked to from your device are not available in all languages or in all countries.
                We make no representation that such services and materials are appropriate or available for use in any
                particular location. To the extent you choose to access such services or materials, you do so at your
                own initiative and are responsible for compliance with any applicable laws, including but not limited to
                applicable local laws.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">7. Indemnity</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">You agree that you will be responsible for your use of the
                Service, and to the maximum extent permitted by law, you agree to defend and indemnify us and our
                officers, directors, employees, consultants, affiliates, investors, business partners, subsidiaries and
                agents (together, the “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Affiliated Parties</span><span
                style="font-family:Arial; font-size:11pt">”) from and against every claim, liability, damage, loss, and
                expense, including reasonable attorneys’ fees and costs, arising out of or in any way connected with:
                (a) your access to, use of, or alleged use of the Service; (b) your violation of any portion of these
                Terms, any representation, warranty, or agreement referenced in these Terms, or any applicable law or
                regulation; (c) your violation of any third-party right, including any intellectual property or
                proprietary right, publicity or privacy right, property right, or confidentiality obligation; or (d) any
                Dispute or issue between you and any third party.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">8. Disclaimers</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">THE SERVICES ARE ALL PROVIDED BY </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
                REPRESENTATIONS, WARRANTIES, COVENANTS OR CONDITIONS OF ANY KIND. </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> AND ITS LICENSORS DO NOT WARRANT OR REPRESENT THAT THE
                SERVICE, OR ANY PART THEREOF, WILL OPERATE UNINTERRUPTED OR ERROR-FREE. </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> AND ITS LICENSORS DISCLAIM ALL WARRANTIES AND REPRESENTATIONS
                (EXPRESS OR IMPLIED, ORAL OR WRITTEN), WHETHER ALLEGED TO ARISE BY OPERATION OF LAW, BY REASON OF CUSTOM
                OR USAGE IN THE TRADE, BY COURSE OF DEALING OR OTHERWISE, INCLUDING ANY AND ALL: (I) WARRANTIES OF
                MERCHANTABILITY; (II) WARRANTIES OF FITNESS OR SUITABILITY FOR ANY PURPOSE (WHETHER OR NOT </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> KNOWS, HAS REASON TO KNOW, HAS BEEN ADVISED OR IS OTHERWISE
                AWARE OF ANY SUCH PURPOSE); AND (III) WARRANTIES OF NONINFRINGEMENT OR CONDITION OF TITLE. NO ADVICE OR
                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM </span><span
                style="font-family:Arial; font-size:11pt">Dobit.link</span><span
                style="font-family:Arial; font-size:11pt"> OR ELSEWHERE WILL CREATE ANY WARRANTY OR CONDITION NOT
                EXPRESSLY STATED IN THESE TERMS.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">YOU SHALL BE SOLELY RESPONSIBLE FOR THE ACCURACY AND QUALITY
                OF YOUR CONTENT, AND YOU UNDERSTAND THAT YOU MUST EVALUATE AND BEAR ALL RISKS ASSOCIATED WITH YOUR USE
                OF THE SERVICE, OR YOUR RELIANCE ON THE ACCURACY, COMPLETENESS, OR USEFULNESS OF THE SERVICE.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">9. No Warranties</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
                YOU FROM US OR ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE WILL CREATE ANY WARRANTY REGARDING
                ANY OF THE AFFILIATED PARTIES OR US THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU ASSUME ALL RISK FOR
                ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF OR ACCESS TO THE SERVICE, YOUR DEALING WITH ANY OTHER USERS
                THROUGH THE SERVICE OR ON THE PLATFORM, AND ANY MATERIALS OR CONTENT AVAILABLE THROUGH THE SERVICE. YOU
                UNDERSTAND AND AGREE THAT YOU USE THE SERVICE, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN MATERIALS
                OR CONTENT THROUGH THE SERVICE AND ANY ASSOCIATED SITES OR SERVICES, AT YOUR OWN DISCRETION AND RISK,
                AND THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR
                MOBILE DEVICE USED IN CONNECTION WITH THE SERVICE), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE
                SERVICE OR THE DOWNLOAD OR USE OF THAT MATERIAL OR CONTENT. THESE LIMITATIONS APPLY TO THE MAXIMUM
                EXTENT PERMITTED BY APPLICABLE LAW.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">10. Limitation of Liability</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU
                EXPRESSLY UNDERSTAND AND AGREE THAT IN NO EVENT WILL THE AFFILIATED PARTIES BE LIABLE TO YOU FOR ANY
                INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES OR PUNITIVE DAMAGES (INCLUDING DAMAGES
                FOR LOSS OF PROFITS, GOODWILL, OR ANY OTHER INTANGIBLE LOSS) ARISING OUT OF OR RELATING TO YOUR ACCESS
                TO OR USE OF, OR YOUR INABILITY TO ACCESS OR USE, THE SERVICE OR ANY MATERIALS OR CONTENT OF THE SERVICE
                OR ON THE PLATFORM, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE, OR ANY
                OTHER LEGAL THEORY, AND WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF DAMAGE.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, YOU
                AGREE THAT THE AGGREGATE LIABILITY OF THE AFFILIATED PARTIES TO YOU FOR ALL CLAIMS ARISING OUT OF OR
                RELATING TO THE USE OF OR ANY INABILITY TO USE ANY PORTION OF THE SERVICE OR OTHERWISE UNDER THESE
                TERMS, WHETHER IN CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO (A) THE AMOUNTS PAID BY YOU IN THE MOST
                RECENT THREE (3) MONTHS FOR USE OF THE SERVICE; OR (B) ONE HUNDRED U.S. DOLLARS (US$100) (EXCEPT AS
                NOTED IN THE ARBITRATION SECTION BELOW).</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
                ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
                WITH ANY PORTION OF THE SERVICE, THE PLATFORM OR WITH THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY
                IS TO DISCONTINUE USE OF THE SERVICES.</span></p>
        <p style="font-size:10.5pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">11. Dispute Resolution, Arbitration and No
                Class Actions</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">This section affects your rights so please read it
                carefully.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) YOU AND WE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST
                THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
                PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. You and we agree as follows: (a) neither you nor we will
                seek to have a Dispute heard as a class action, private attorney general action, or in any other
                proceeding in which either party acts or proposes to act in a representative capacity; (b) no
                arbitration or proceeding can be combined with another without the prior written consent of all parties
                to the arbitration or proceeding; and (c) if the class action waiver or any part of this Section 11 is
                found to be illegal or unenforceable as to all or some parts of a Dispute, those parts will be severed
                and proceed in a court of law, with the remaining parts proceeding in arbitration.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) For the purpose of these Terms, “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">Dispute(s)</span><span
                style="font-family:Arial; font-size:11pt">” shall mean any dispute, action, controversy or claim arising
                out of or relating to any aspect of these Terms, whether based on contract, tort, statute, fraud,
                misrepresentation, or any other legal or equitable basis, and regardless of whether a claim arises
                during or after the termination of these Terms.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(c) These Terms are governed by the laws of Hong Kong without
                giving effect to any choice of law that would cause the application of the laws of any jurisdiction
                other than the internal laws of Hong Kong to the rights and duties of the parties.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(e)  Dispute Resolution</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">i.  You and we agree to arbitrate all Disputes between you and
                us or our affiliates. You and we empower the arbitrator with the exclusive authority to resolve any
                Dispute, including without limitation whether or not any part of these Terms is void or voidable.</span>
        </p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">ii.  You and we agree that nothing in these Terms will be
                deemed to waive, preclude, or otherwise limit your or our right to: (a) pursue an enforcement action
                through the applicable court if that action is available; (b) seek temporary, preliminary or other
                expedited or provisional injunctive relief (but not money damages); or (c) file suit in a court of law
                to address an intellectual property infringement claim or to compel or uphold any arbitration decision
                hereunder. In such cases, neither you nor we shall need to follow the informal negotiations procedure
                and timeline set out in the next subsection.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iii. To help get you and us to a resolution and to control
                costs for you and us regarding any Dispute, you and we agree to first attempt to informally discuss and
                try to negotiate a resolution to any Dispute for at least 90 days from when notice of the Dispute is
                sent. Those informal negotiations will commence upon written notice from you to us or us to you. We will
                send our notice to your registered email address and any billing address that you provided to us. If the
                Dispute cannot be resolved within that time period, and if either you or we desire to continue the
                Dispute, the party desiring to continue the Dispute shall commence arbitration.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">iv. If you and we do not resolve a Dispute by informal
                negotiation, the Dispute shall be referred to and finally resolved by arbitration administered by the
                Hong Kong International Arbitration Center (the “</span><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">HKIAC</span><span
                style="font-family:Arial; font-size:11pt">”) in accordance with the HKIAC Administered Arbitration Rules
                in force at the time of the commencement of the arbitration. The law of this arbitration clause shall be
                Hong Kong</span><span style="font-family:Arial; font-size:11pt"> law. </span><span
                style="font-family:Arial; font-size:11pt">The seat of arbitration shall be Hong Kong. The number of
                arbitrators shall be three. The arbitration proceedings shall be conducted in English. Arbitration may
                be conducted in person, through the submission of documents, by phone or online. The arbitrator may
                award damages to you individually as a court could, including declaratory or injunctive relief, but only
                to the extent required to satisfy your individual claim.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">12. Survival</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">Any part of these Terms which by their nature should survive
                the termination of these Terms, shall survive such termination, including without limitation Sections 5,
                8, 9, 10, 11, 12, and 13.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">13. Waiver</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">No waiver of any part of these Terms by us shall be deemed a
                continuing or further waiver of any such part or any other part of the Terms, and our failure to assert
                any rights or part of these Terms shall not be deemed or otherwise constitute a waiver of such right or
                part.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">14. General</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt; font-weight:bold">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(a) These Terms, together with the other documents expressly
                incorporated by reference into these Terms, are the entire and exclusive understanding and agreement
                between you and us regarding your use of the Service.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(b) The English language version of the Agreement is legally
                binding in case of any inconsistencies between the English version and any translations.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(c) You may not assign or transfer these Terms or your rights
                under these Terms, in whole or in part, by operation of law or otherwise, without our prior written
                consent. We may assign these Terms at any time to any entity without notice or consent.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(d) These Terms are binding on and inures to the benefit of
                our respective heirs, successors and assigns.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(e) Use of section headers in these Terms is for convenience
                only and will not have any impact on the interpretation of any provision.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(f) If any part of these Terms is held to be invalid or
                unenforceable, the unenforceable part will be given effect to the greatest extent possible (or, if it
                cannot legally be given any effect, will be severed from these Terms), and the remaining parts will
                remain in full force and effect.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(g) Nothing in these Terms shall be deemed to confer any
                rights or benefits on a third party.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(h) You will comply with all laws in your use of the Service,
                including any applicable export laws. You will not directly or indirectly export, re-export, or transfer
                the Service to prohibited countries or individuals or permit use of the Service by prohibited countries
                or individuals.</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(i) You agree that regardless of any statute or law to the
                contrary, any claim or cause of action arising out of or related to use of the Service or these Terms
                must be filed within one year after such claim or cause of action arose or be forever barred.</span></p>
        <p style="font-size:10.5pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:120%; margin:6pt 0pt; orphans:0; widows:0"><span
                style="font-family:Arial; font-size:11pt">(</span><span
                style="font-family:Arial; font-size:11pt">j</span><span style="font-family:Arial; font-size:11pt">) The
                Service is offered by Kotoko Inc.</span><span style="font-family:Arial; font-size:11pt">,</span><span
                style="font-family:Arial; font-size:11pt"> </span><span
                style="font-family:Arial; font-size:11pt">located at </span><span
                style="font-family:Arial; font-size:11pt">200 CONTINENTAL DRIVE STE 401 RM434</span><span
                style="font-family:Arial; font-size:11pt">, </span><span
                style="font-family:Arial; font-size:11pt">NEWARK, DE 19713</span><span
                style="font-family:Arial; font-size:11pt">, United States. </span><span
                style="font-family:Arial; font-size:11pt">You may contact us by sending correspondence to that address
                or email us at </span><span style="font-family:Arial; font-size:11pt">moc@dobit.link</span><span
                style="font-family:Arial; font-size:11pt">.</span></p>
    </div>`
