
import React from 'react'
import index from './htmlString'
export default function Pdf(



) {
    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        overflow: 'scroll'
    }}>
        <div style={{ width: '1200px' }} dangerouslySetInnerHTML={{ __html: index }}></div>
    </div>
}