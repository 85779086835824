

import x from './images/Aspose.Words.38536138-02a4-4572-92ff-6564f6f9c7c7.001.jpeg'



 const htmlString=`<div>
    <div style="-aw-headerfooter-type:header-primary; clear:both">
        <p><span style="-aw-import:ignore">&#xa0;</span></p>
    </div>
    <p style="margin-top:24pt; margin-bottom:24pt; line-height:120%; font-size:26pt"><span
            style="font-family:Arial; font-weight:bold">Dobit.link Privacy Policy</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">Dobit.link Privacy Policy</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:center; line-height:120%"><span
            style="-aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Release Date of this Privacy Policy: August 28, 2023</span>
    </p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Effective Date of this Privacy Policy: August 29,
            2023</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%"><span
            style="-aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Welcome to use Dobit.link applications and become a part of
            our creative community!</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Kotoko Inc. and designated affiliates (</span><span
            style="font-family:Arial; font-weight:bold">“we”</span><span style="font-family:Arial"> or </span><span
            style="font-family:Arial; font-weight:bold">“Dobit.link”</span><span style="font-family:Arial">)
            respects your right to privacy and value the protection of your Personal Data (as defined below). This
            Privacy Policy (“</span><span style="font-family:Arial; font-weight:bold">Privacy Policy</span><span
            style="font-family:Arial">”) explains how we collect, process and protect your Personal Data when you
            use Dobit.link’s website, i.e. www.dobit.link (“</span><span
            style="font-family:Arial; font-weight:bold">Website</span><span style="font-family:Arial">”), and how
            you can exercise your rights to Personal Data protection.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial">“Personal Data” in this Privacy Policy refers to all information in relation
            to an identified or identifiable natural person, including nicknames, email address, passwords, chat
            history, social account information, etc., excluding anonymized information.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">If you have any questions about this Privacy Policy, please
            contact us using the contact details provided at the bottom of this Privacy Policy.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">This Privacy Policy informs you of the following
            information:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">1. What types of Personal Data do we collect and our
            purposes</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">2. How do we share and entrust the processing of your
            Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">3. How do we protect your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">4. How do we store your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">5. Lawful basis for processing Personal Data (applicable to
            users located in the EU/EEA)</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">6. Your rights</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">7. How do we update this Privacy Policy</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">8. How to contact us</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">Before using the Website or submitting any Personal Data to
            us, please review this Privacy Policy carefully. By using the Website, you agree to the practices
            described in this Privacy Policy. If you do not agree to this Privacy Policy, you may not access the
            Website or otherwise use our services.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%"><span
            style="-aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">1. What types of Personal Data do we collect and our
            purposes</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">The scope of Personal Data we collect depends on the
            functions of the Website you use and how you use those functions. The purposes and types of Personal
            Data we collect are summarized below.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">A. The Personal Data you directly provide to us</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Registration and
            Login</span><span style="font-family:Arial; font-weight:bold">: </span><span style="font-family:Arial">When
            you register an account on our Website, we will collect your nickname,
            password, email or mobile phone number to help you complete the registration and login process. If you
            refuse to provide such Personal Data, you will not be able to complete the registration and login
            process.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Contact and Marketing</span><span
            style="font-family:Arial; font-weight:bold">: </span><span style="font-family:Arial">We may use the
            contact information provided by you (e.g., email address, cell phone number) to send you information
            about products and services that may be of interest to you. Where required by applicable laws and
            regulations, we will obtain your consent before sending you marketing messages. If you are not
            interested in such information, you may refuse at any time during the communication, for example, by
            clicking on the unsubscribe option or sending us a reply to opt-out.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Inquiries and customer
            service</span><span style="font-family:Arial; font-weight:bold">:</span><span style="font-family:Arial">
            Information that you provide to submit inquiries to us, like your name, contact details and
            communication between you and our customer service.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Surveys</span><span
            style="font-weight:bold">：</span><span style="font-family:Arial">Information that you provide when you
            voluntarily attend our surveys.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Other Features</span><span
            style="font-family:Arial; font-weight:bold">: </span><span style="font-family:Arial">Information that
            you provide through other features we make available on the Website from time to time, where you will be
            informed about why and what kind of Personal Data we may collect separately.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">B. The Personal Data provided by you or generated during
            your use of the Website</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Interaction and Content
            Creating</span><span style="font-family:Arial">: Your interactions with characters, including
            conversations and storylines, may generate personal data about your interests, beliefs, and
            relationships. The cultivation of dynamic personas for characters based on you nurturing may also result
            in the collection of personal data about the your personality, interests, and social connections.</span>
    </p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="text-align:left; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Social Network</span><span
            style="font-family:Arial">: The social network functions of the Website, which may allow you to connect
            with other users through their cultivated characters, may generate personal data about the your social
            connections and relationships. Profile information shared between you and other users may contain
            personal data such as nickname, location, interests, and other details about you. Additionally, the
            content of social interactions and conversations between you and other users may reveal personal
            information about the your</span><ins
            style="color:#b5082e; -aw-revision-author:'GLO'; -aw-revision-datetime:'2023-08-18T14:50:00'"><span
                style="font-family:Arial; -aw-import:spaces">&#xa0;</span></ins><span style="font-family:Arial">lives,
            interests, beliefs, and relationships with others.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Feedback and Help</span><span
            style="font-family:Arial">: When you use the Feedback function within the Website, we collect the type
            of questions, descriptions, attachments, and other system information you submit. If you do not provide
            this information, we may not be able to help you resolve the issue or respond to your question. In
            addition, if you need to ask for our help, you can contact us through private message or our groups
            within third-party chat software (such as Discord) and we will assist you based on our communications
            with you.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Payment and
            Transaction</span><span style="font-family:Arial">: We offer various props and digital assets in our
            experience, some of which you will need to pay for. If you initiate a payment request, we need to
            collect your payment amount, payment method, order number and other information to complete the payment
            and transaction process.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Maintaining stability and
            improving user experience</span><span style="font-family:Arial">: In order to maintain the stability of
            the Website and the security of your account, and to improve your experience (e.g. to perform data
            analysis and push notifications to you), we collect data related to the operation of the applications
            and your online operations, such as login status, click data, device data (e.g. device identifiers),
            application anonymous identifiers, application Token, information related to the operating system,
            network information (e.g. IP address), etc.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">C. The Personal Data provided by third parties</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; text-decoration:underline">Third-Party Login</span><span
            style="font-family:Arial; font-weight:bold">: </span><span style="font-family:Arial">To make it easier
            to use the Website, you may also choose to register and sign in within Dobit.link through an account on a
            third-party platform (e.g. Google, Facebook, Apple). We will collect your third-party nickname and Open
            ID, and by registering and logging in through these third-party accounts, you are consenting to the
            third party providing us with the Personal Data concerned.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">If you provide us with any Personal Data of any data
            subjects other than yourself, please bring this Privacy Policy to their attention. You hereby
            acknowledge that you have obtained their consent or completed other formality as required by other
            lawful bases for the Personal Data processing in lieu of consent before providing such Personal Data to
            us. By submitting such information to us, you represent to us that you are entitled to provide and we
            are entitled to process relevant Personal Data for the purposes as stated in this Privacy Policy.</span>
    </p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">In general, we will use your Personal Data only for the
            purposes described in this Privacy Policy or for purposes that we explain to you at the time we collect
            relevant Personal Data. However, we may also process Personal Data for other purposes that are not
            incompatible with the purposes that we have disclosed to you if and where it is permitted by applicable
            laws and regulations.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">D. Personal Data collected automatically when you are
            visiting or using our Website</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">When you visit our Website, we may automatically collect
            certain Personal Data about you or the device you are using, like your IP address, device type, unique
            device identification numbers, browser type, broad geographic location (e.g. country or city-level
            location), the time of your visit, and the content that you have viewed.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Collecting this Personal Data enables us to better
            understand the visitors who come to our Website, where they come from, and what content on our Website
            is of interest to them. We use this Personal Data for our internal analytics purposes and to improve the
            quality and relevance of our Website to our visitors.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Some of this Personal Data may be collected using cookies
            and similar tracking technology, as explained further in our Cookie Notice. </span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">E. Links to third-party websites</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Our Website may contain links to third-party websites,
            which are normally operated by third parties independently from us. This Privacy Policy does not
            address, and we are not responsible for, the privacy practices of any such third parties. You need to
            refer to their own privacy policy to understand how they may process your Personal Data. The inclusion
            of a link on our Website does not imply that we endorse the practices of the linked website or
            services.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">F. Age requirement </span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial">If you are under 13 years old, you are not authorized to register to use the
            Website. If you are between the ages of 13 and 16, please get permission from your parent or guardian to
            use the Website.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%"><span
            style="-aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">2. How do we share and entrust the processing of your
            Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">In order to improve and maintain our services, we may
            engage third parties (such as those who assist us in providing services related to our Website and
            applications) to process your Personal Data on our behalf. We will enter into strict confidentiality
            agreements with such third parties and require them to process Personal Data in accordance with our
            requirements, this Privacy Policy, and the confidentiality and security measures required by applicable
            laws and regulations.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We may also share your Personal Data with third-parties in
            the following circumstances:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">In the event of any change of control, merger, acquisition,
            reorganization, or liquidation</span><span style="font-family:Arial"> involving the transfer of Personal
            Data, we will ask the new company or organization handling your Personal Data to continue to process
            your Personal Data in accordance with this Privacy Policy or its updated version. If the new company or
            organization handling your Personal Data needs to use your Personal Data for purposes not stated in this
            Privacy Policy, the new company or organization will obtain your consent, unless otherwise provided by
            the applicable laws and regulations.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial">To relevant </span><span style="font-family:Arial; font-weight:bold">competent
            law enforcement body, regulatory, government agency, court or other third parties</span><span
            style="font-family:Arial"> where we believe that such disclosure is to (1) comply with an applicable law
            or regulation; (2) exercise, establish or defend our legal rights; or (3) protect your vital interests
            or those of any other person.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial">Prior to sharing, where required by applicable laws and regulations, we may
            separately inform you of the names of the third parties, their contact information, the purpose and
            manner of processing and the types of Personal Data, and obtain your consent (or complete any formality
            as required by other lawful bases).</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">3. How do we protect your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You use the Website at your own risk. We use appropriate
            technical and organizational measures to protect your Personal Data that we collect and process. The
            measures that we use are designed to provide a level of security appropriate to the risk of processing
            your Personal Data. </span><span style="font-family:Arial; font-weight:bold">Please be aware and
            understand that we cannot ensure an absolutely secure Internet. If you find that your Personal Data is
            breached, please contact us using the contact details provided in “How to contact us” immediately so
            that we can take corresponding measures.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">In the event of any accident, force majeure event or other
            circumstances leading to the breach of your Personal Data, we will make every effort to control the
            situation and promptly inform you of the cause, the security measures we have taken and you can take and
            other relevant information. In the event of a security incident related to Personal Data, we will report
            to the competent authorities in accordance with the requirements of applicable laws and regulations,
            promptly investigate the problem, and take emergency measures.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">4. How do we store your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">A. Storage of Data and Cross-border Transfer</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Your Personal Data collected by our Website and the
            applications is saved in our data base in the United States. For the need of our global operation, your
            Personal Data may be transferred to, and stored, at a jurisdiction different from the one you are
            located. Such jurisdiction may not provide the same level of data protection as your jurisdiction. Under
            such circumstances, we will take all necessary and appropriate safeguards to ensure the security of your
            Personal Data in transit and require that the recipient of your Personal Data offers an adequate level
            of protection and security in accordance with this Privacy Policy and applicable laws and regulations,
            for instance by entering into the appropriate data processing agreements and, if necessary, standard
            contractual clauses with the recipient or adopting an alternative mechanism for the transfer of Personal
            Data as approved by applicable regulators.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">B. Retention Period</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We will store your Personal Data only to the extent as
            necessary to the proper business needs of our company (e.g. for the purposes of providing service to you
            or complying with the requirements of laws, tax and finance), except as provided otherwise by applicable
            laws or regulations. When there is no such business need to use your Personal Data or the time limit for
            retention expires according to applicable laws or regulations, we will delete or anonymize your Personal
            Data. For example, we store your chat data in Dobit.link for only seven days, and we will delete such
            data immediately after the expiration date.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">5. Lawful basis for processing Personal Data (applicable to
            users located in the EU/EEA)</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Our lawful basis for collecting and using the Personal Data
            described in this Privacy Policy will depend on the Personal Data concerned and the specific context in
            which we collect it. The specific lawful basis on which we rely for each of the purposes which we
            process your Personal Data for is set forth below:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; line-height:120%"><img
            src=${x} width="564" height="235" alt=""
            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
    </p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial">When choosing to use your data on the basis of our legitimate interests, we
            always use the balance test to weigh our interests against your interests or fundamental rights and
            freedoms. If you would like to obtain information on the balancing test regarding the legal basis for
            data processing, please contact us by using the contact details provided in the “How to contact us”
            section below.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">6. Your rights</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We value the protection of your Personal Data. If you want
            to access, correct, or delete your Personal Data, or if you want to refuse marketing messages, file a
            complaint, withdraw consent, or exercise other rights under applicable laws and regulations, please do
            so as described below or contact us. Please note that certain rights may be restricted according to the
            GDPR and other applicable legislation.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">A. Access your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You have the right to access the Personal Data that we
            collect about you. For Personal Data such as nicknames, user names, characters, account emails, personal
            profiles, etc. that you voluntarily provide to us, you can view them on your personal page within the
            Website; for Personal Data that you provide and generate while using the Website, as well as Personal
            Data that third parties provide to us (e.g. chat messages, payment information, etc.), you can access
            them within the corresponding functions of Dobit.link. You also have the right to request a copy of your
            Personal Data from us, by contacting us using the contact details provided in the “How to contact us”
            section below.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">B. Correct your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We will endeavor to ensure that your Personal Data is
            accurate and up-to-date by implementing appropriate measures. If your Personal Data is inaccurate or
            incomplete, you have the right to correct such Personal Data. For Personal Data such as nicknames, user
            names, characters, account emails, profiles, passwords, etc. that you voluntarily provide to us, you can
            correct them within your personal page within the Website; for Personal Data that you provide and
            generate while using the Website that third parties provide to us (e.g. chat messages, payment
            information, etc.), if you think such Personal Data needs to be corrected, you can contact us using the
            contact details provided in the “How to contact us” section below. Please note that for Personal Data
            provided by third parties (such as the nickname and Open ID of third-party platform account), we may not
            be able to keep the information up to date and may need you to contact the third-party for
            corrections.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">C. Delete your Personal Data</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You have the right to request that we delete your Personal
            Data, including your Dobit.link account and related Personal Data (including account number, nickname,
            email address, profile, etc.). You can do this on your personal page within the Website or by using the
            contact details provided in the “How to contact us” section below. Please note that after you submit a
            request to delete your account, you will no longer be able to sign in to your Dobit.link account or use
            Dobit.link's applications, so please decide carefully. In addition, we will also voluntarily delete your
            Personal Data after the expiration of the retention period of the Personal Data when the purposes of
            processing in the first part of this Privacy Policy no longer exist.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">If we terminate our services or operations, we will notify
            you at least thirty (30) days in advance and will delete or anonymize your Personal Data after
            termination of services or operations.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">D. Right to opt-out marketing</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You can exercise this right by clicking on the
            “unsubscribe” or “opt-out” option (or other similar option or link) when you register account with us or
            in the marketing messages we send you.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">E. Right to file a complaint</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You have the right to file a complaint with the data
            protection authority about our collection and use of your Personal Data. For more information, please
            contact your local data protection authority. Before you file a complaint, we also sincerely hope that
            you will first provide us with your comments and we will endeavor to answer your questions and protect
            your rights.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">F. Right to withdraw consent</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">If we process your Personal Data based on your consent, you
            may withdraw your consent at any time. Please note that withdrawal of your consent will not affect the
            lawfulness of any processing we have carried out prior to your withdrawal of consent, nor will it affect
            the lawfulness of our processing of your Personal Data based on a lawful basis other than your
            consent.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">G. Other rights under applicable laws and regulations</span>
    </p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">If you want to exercise your statutory data subject rights
            under other applicable laws and regulations (such as requiring us to transfer your Personal Data to a
            third party designated by you, or restricting our processing of your Personal Data, or refusing to allow
            us to process your Personal Data based on our legitimate interests, if the statutory conditions are
            met), you may contact us using the contact details provided in the “How to contact us” section
            below.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">H. Identity verification and responding to your
            requests</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">In order to protect the security of your information, we
            may need to verify your identity before responding to your requests to exercise your rights, for
            example, by requiring you to provide certain identity-related supporting information.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We will respond to your request to exercise your data
            subject rights in accordance with the requirements under applicable laws and regulations and in a timely
            manner.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">7. How do we update this Privacy Policy</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">We may update this Privacy Policy from time to time in
            response to the changes in legal, technical or business developments. When we update our Privacy Policy,
            we will take appropriate measures to inform you, for example by email, by displaying a notice on our
            Website or in-app notice prior to the effective date of the updated privacy policy, depending on the
            significance of the changes we make. Before we make material changes to this Privacy Policy, we will
            make sure you can accept such changes, as required by applicable laws and regulations.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">You can see when this Privacy Policy came into effect by
            checking the “Effective Date” displayed at the top of this Privacy Policy. </span><span
            style="font-family:Arial; font-weight:bold">If you continue to use any functions of the Website, you are
            deemed to have accepted the updated Privacy Policy.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold">8. How to contact us</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">If you have any questions or concerns about this Privacy
            Policy or our processing upon your Personal Data, especially if you consider that our processing of your
            Personal Data impairs your legitimate interests, please contact us using the contact details below. We
            will review your question without delay and make a timely reply according to the applicable laws and
            regulations. Our contact details are as follows:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">US:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Company Name: Kotoko Inc.</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Address: 200 CONTINENTAL DRIVE STE 401 RM434, NEWARK, DE
            19713</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">E-mail:</span><span style="line-height:120%; font-size:10.5pt">
        </span><span style="font-family:Arial; font-style:italic">moc@dobit.link</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:ignore">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Hong Kong:</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Company Name: Kotoko Technology Limited</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">Address: RM 4, 16/F HO KING COMM CTR, 2-16 FAYUEN ST
            MONGKOK KLN, HONG KONG</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic">E-mail:</span><span style="line-height:120%; font-size:10.5pt">
        </span><span style="font-family:Arial; font-style:italic">moc@dobit.link</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-style:italic; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%; font-size:11pt"><span
            style="font-family:Arial; font-weight:bold; -aw-import:spaces">&#xa0;</span></p>
    <p style="margin-top:6pt; margin-bottom:6pt; text-align:left; line-height:120%"><span
            style="-aw-import:ignore">&#xa0;</span></p>
    <div style="-aw-headerfooter-type:footer-primary; clear:both">
        <p><span style="-aw-import:ignore">&#xa0;</span></p>
    </div>
</div>`

export default htmlString