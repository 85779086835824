import React, { useCallback } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Element, Link } from 'react-scroll';
import './App.css';
import ca from './img/ca.png';
import dis from './img/dis.png';
import fb from './img/fb.png';
import ins from './img/ins.png';
import logo from './img/logo.png';
import startIcon from './img/start.png';
import x from './img/x.png';
import Privacy from './page/privacy/index.tsx';
import Tos from './page/tos/index.tsx';
const Header = () => {
    return (
        <header className="header">
            <div className='headerBox'>
            <div className="logo"><img src={logo} alt="" /></div>
            <nav className="navigation">
                <Link to="about" smooth={true} duration={500} className="nav-link">HOME</Link>
                <Link to="multi-agent" smooth={true} duration={500} className="nav-link">PRODUCTS</Link>
                <Link to="footer" smooth={true} duration={500} className="nav-link">JOIN US</Link>
            </nav>
            </div>
          
        </header>
    );
};

const About = () => {
    return (
        <Element name="about" className="about">
            <h1>AUTONOMOUS WORLDS FOR DIGITAL MANKIND</h1>
            <p>Bringing conscious growth to AI agents</p>
            <div className="hearts">
                <img src={startIcon} alt="" />
            </div>
            <div className='AboutUs'>About Us</div>
            <div className='tip'>Kotoko is a laboratory dedicated to researching AI and the relationships between AIs. Our team includes scientists, artists, game designers, and business leaders.</div>
        </Element>
    );
};

const Section = ({ name, title, content, button1Text, button2Text, alignment }) => {
    const sectionClasses = `section ${alignment === 'left' ? 'left-align' : 'right-align'}`;
    const goPath = useCallback((path) => {
        window.open(path)
      }, [])
    return (
        <Element name={name} className={sectionClasses}>
            <div className="section-content-wrapper">
                <div className="section-title">{title}</div>
                <p className="section-content">{content}</p>
                <div className="buttons">
                    {button1Text && <button className="button" onClick={()=>{goPath('https://www.dobit.link/')}}>{button1Text}</button>}
                    {button2Text && <button className="button"  onClick={()=>{goPath('https://discord.gg/sKrjZyWR56')}}>{button2Text}</button>}
                </div>
            </div>
        </Element>
    );
};

const Footer = () => {
    const goPath = useCallback((path) => {
        window.open(path)
      }, [])
    return (
        <footer className="footer">
            <div className='footerBox'>
            <div className="footer-content">
                <div className="footer-section company">
                    <h4>Company</h4>
                    <a href="mailto: moc@dobit.link">Contact us </a>
                </div>
                <div className="footer-section legal">
                    <h4>Legal</h4>
                    <a href="/privacy-policy">Privacy Policy</a>
                    <a href="/terms-service">Terms & Service</a>
                </div>
                <h4 className="footer-section social">
                    <img src={x} onClick={() => { goPath('https://twitter.com/Dobit_EN') }} alt="" />
                    <img src={dis} onClick={() => { goPath('https://discord.gg/sKrjZyWR56')  }} alt="" />
                    <img src={fb} onClick={() => {goPath('https://www.facebook.com/profile.php?id=61556562321311') }} alt="" />
                    <img src={ins} onClick={() => { goPath('https://www.instagram.com/dobits_official') }} alt="" />
                </h4>
            </div>
            <div className="footer-bottom">
                © 2024 Kotoko Inc. All rights reserved
            </div>
            </div>
        </footer>
    );
};
const JoinUs = () => {
    return (
        <div className='JoinBox'>
            <div className="JoIn">
                <div className="image-section">
                    <img src={ca} alt="Illustration" />
                </div>
                <div className="text-section">
                    <p>
                        We are a dynamic global team with roots in the United States, Japan, and Hong Kong. We offer both remote and on-site work opportunities. Our team has extensive experience in AI, gaming, and social products.
                    </p>
                    <div className="cta-section">
                        <a href="mailto:hiring@kotoko.ai">JOIN US </a>
                    </div>
                </div>
            </div>
        </div>
    );
};


const HomePage = () => {
    return (<>

        <div className="App">
            <Header />
            <About />
            <Section
                name="multi-agent"
                title="MULTI-AGENT SOCIAL SIMULATION"
                content="Empower users to create, observe, intervene, and grow alongside their AI agents in a dynamic virtual environment, where players can influence their agents’ daily lives and connect with other players. Our multi-agent simulation game has completed the Alpha test and will soon launch the Open Beta!"
                button1Text="PREVIEW"
                button2Text="DISCORD"
                alignment="left"
            />
            <Section
                name="agentic-economy"
                title="AGENTIC ECONOMY"
                content="Social agents with the economic layer are the dawn of a new digital civilization. By fusing economic design in gaming and the real world with cutting-edge multi-agent technology, we create dynamic economic ecosystems where agents thrive and interact seamlessly."
                alignment="right"
            />
            <Section
                name="digital-living"
                title="DIGITAL LIVING ENTITY"
                content="Transforming every human action into an atomic tool for agents in virtual worlds, we are dedicated to evolving these agents into free souls. We human beings will transition from being mere copilots to becoming integral members of an agentic society."
                alignment="left"
            />
            <JoinUs />
        </div>
        <Footer />
    </>
    );
};



const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/terms-service" element={<Tos />} />
                <Route path="/privacy-policy" element={<Privacy />} />
            </Routes>
        </Router>
    );
};

export default App;
